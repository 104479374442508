import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import remark from 'remark'
import remarkHTML from 'remark-html'
import Access from "../components/access"
import Footer from "../components/footer"

const toHTML = value => remark()
                    .use(remarkHTML)
                    .processSync(value)
                    .toString()

export default function ZutrittskontrolleDefault({ data, location }) {
  const post = data.markdownRemark

  return (
    <Layout location={location}>
      <Access>
        <h1>{ post.frontmatter.title }</h1>
        <div
          dangerouslySetInnerHTML={{ __html: toHTML(post.html) }}
        />
      </Access>
      <Footer></Footer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
      fields {
        slug
      }
    }
  }
`