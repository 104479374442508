import React from 'react'
import ContentWrapper from '../styles/ContentWrapper'
import styled from 'styled-components'

const StyledContentWrapper = styled(ContentWrapper)`
    max-width: 1000px;

    h1 {
        line-height: 1.2;
    }

    a {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.secondary};
        padding: 0.8rem 1.8rem;
        border-radius: 50px;
    }

    .gatsby-resp-image-wrapper {
        margin: 4rem 0rem;
        display: block!important;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        .gatsby-resp-image-wrapper {
            margin: 1rem 0rem;
            display: block!important;
        }
    }
`

export default function Access(props) {
    return (
        <StyledContentWrapper>
            {props.children}
        </StyledContentWrapper>
    )
}
